@import "~antd/dist/antd.less";

@primary-color: #516db4;

#loading-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loading-root svg {
    fill: transparent;
    stroke: #1a202c;
    stroke-width: 28px;
    animation: dash 2s ease infinite, rotate 6s linear infinite;
}

@keyframes dash {
    0% {
        stroke-dasharray: 10, 950;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 850, 950;
        stroke-dashoffset: -250;
    }

    100% {
        stroke-dasharray: 850, 950;
        stroke-dashoffset: -930;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
